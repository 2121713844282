.Feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  padding-top: 2rem;

  /* background-image: url("../../img/feedback.jpg"); */
  /* background-size: cover;
  background-attachment: fixed; */
}

.Feedback .Feedback-header {
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    263deg,
    rgba(85, 132, 172, 1) 0%,
    rgb(123, 176, 223) 100%
  );
  color: #fff;
  height: 5rem;
}

.Feedback .Feedback-header h2 {
  margin: 0;
  font-weight: 300;
  font-size: 3rem;
}

.Feedback .Feedback-section {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  gap: 1rem;
  padding-top: 2rem;
}

.Feedback .Feedback-block {
  width: calc(50% - 7rem);
  box-shadow: 0px 5px 25px 0px rgb(0 0 0 / 20%);
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 5px;
  background-color: white;
  padding: 3rem;
  gap: 1rem;
}

.Feedback .Feedback-block .Feedback-author {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-style: italic;
}

.Feedback .Feedback-block .Feedback-content q {
  margin: 1rem;
}

.Feedback .Feedback-block .Feedback-content span {
  text-align: right;
  font-style: italic;
  display: block;
}

.Feedback .Feedback-block .Feedback-date {
  font-size: 17px;
}

.Feedback button {
  border: none;
  padding: 1rem 2rem;
  margin: 1rem 0;
  background: linear-gradient(
    263deg,
    rgba(34, 87, 126, 1) 0%,
    rgb(50, 131, 189) 100%
  );
  margin-left: 24px;
  border-radius: 5px;
  color: white;
  font-size: 17px;
  font-weight: bold;
}

@media screen and (max-width: 991px) {
  .Feedback .Feedback-section {
    padding-top: 1rem;
  }

  .Feedback .Feedback-block {
    width: auto;
    margin: 1rem;
    padding: 1rem 0.5rem 1rem 1.5rem;
  }
  .Feedback .Feedback-block .Feedback-content {
    padding: 0;
  }

  .Feedback .Feedback-header {
    max-width: 100%;
    padding: 0.5rem;
    border-radius: unset;
    height: 4rem;
  }

  .Feedback .Feedback-header h2 {
    font-size: 2rem;
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #00000088;
  backdrop-filter: blur(10px);
  z-index: 20;
  display: flex;
}

.Overlay .Overlay-content {
  margin: auto;
  padding: 3px;
}

.Overlay .Overlay-button {
  position: fixed;
  top: calc(50% - 25px);
  right: 0;
}
.Overlay .Overlay-button.rotated {
  transform: rotate(180deg);
  right: unset;
}

@media screen and (max-width: 991px) {
  .Overlay .Overlay-content {
    width: 100%;
  }
}

.EmailForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  margin-top: -250px;
  padding: 5rem 1rem;
  z-index: 1;
}

.EmailForm .EmailForm-block {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1200px;
  background-color: #fff;
  box-shadow: 0px 5px 25px 0px rgb(0 0 0 / 20%);
  border-radius: 5px;
}

.EmailForm .EmailForm-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 5rem;
  color: #fff;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  background: rgb(34, 87, 126);
  background: linear-gradient(
    263deg,
    rgba(34, 87, 126, 1) 0%,
    rgb(50, 131, 189) 100%
  );
}

.EmailForm .EmailForm-header h2 {
  margin: 0 0 0 1rem;
  font-weight: 300;
  font-size: 3rem;
}

.EmailForm .EmailForm-content {
  padding: 2rem 3rem;
}

.EmailForm .EmailForm-content .EmailForm-row {
  display: flex;
  align-items: center;
}

.EmailForm .EmailForm-content .EmailForm-row p {
  margin: 3rem 0;
  font-size: 1.7rem;
  font-weight: 300;
}

.EmailForm .EmailForm-content .EmailForm-row img {
  height: 300px;
  margin-left: 50px;
}

.EmailForm .EmailForm-content .EmailForm-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: -50px;
}

.EmailForm .EmailForm-content .EmailForm-message img {
  height: 250px;
}

.EmailForm .EmailForm-content .EmailForm-message p {
  margin: -2rem 0 0;
  color: #22577e;
  font-size: 1.7rem;
}

.EmailForm .EmailForm-content .EmailForm-message button {
  border: 2px solid #22577e;
  background-color: #5584ac22;
  color: #22577e;
  margin: 2rem 0;
  border-radius: 5px;
  padding: 1rem 3rem;
  font-weight: 200;
  transition: all 0.1s ease-in-out;
  font-size: 19px;
}

.EmailForm .EmailForm-form {
  display: flex;
  flex-direction: column;
}

.EmailForm .EmailForm-form label {
  margin-top: 1rem;
  margin-bottom: 3px;
  color: #22577e;
}

.EmailForm .EmailForm-form input {
  height: 2rem;
  font-size: 1.3rem;
  border-radius: 5px;
  border: none;
  padding-left: 15px;
  background-color: #95d1cc33;
  color: #22577e;
}

.EmailForm .EmailForm-form textarea {
  resize: none;
  height: 10rem;
  font-size: 1.3rem;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #95d1cc33;
  font-family: unset;
  color: #22577e;
}

.EmailForm .EmailForm-form button {
  border: none;
  background-color: #5584ac;
  color: #fff;
  margin-top: 2rem;
  border-radius: 5px;
  padding: 1rem 3rem;
  font-weight: 200;
  transition: all 0.1s ease-in-out;
  font-size: 19px;
}

@media screen and (max-width: 991px) {
  .EmailForm {
    margin-top: 1rem;
    padding: 0;
  }

  .EmailForm .EmailForm-content {
    padding: 1rem;
  }

  .EmailForm .EmailForm-header {
    padding: 0.8rem;
    height: 4rem;
    border-radius: unset;
  }

  .EmailForm .EmailForm-header h2 {
    font-size: 1.7rem;
  }

  .EmailForm .EmailForm-content .EmailForm-row {
    flex-direction: column-reverse;
  }

  .EmailForm .EmailForm-content .EmailForm-row img {
    margin-left: 0;
    align-self: flex-end;
  }

  .EmailForm .EmailForm-content .EmailForm-row p {
    margin: 1rem 0 0;
    font-size: 1.5rem;
    font-weight: 300;
  }

  .EmailForm .EmailForm-content .EmailForm-message {
    margin-top: -20px;
  }

  .EmailForm .EmailForm-content .EmailForm-message img {
    height: 200px;
  }

  .EmailForm .EmailForm-content .EmailForm-message p {
    margin: -2rem 0 0;
    font-size: 1.5rem;
  }
}

.App .Up-arrow {
  position: fixed;
  right: 40px;
  bottom: -86px;
  width: 72px;
  filter: drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.5));
  transition: transform 0.1s ease-in-out, bottom 0.5s ease-in-out;
  /* opacity: 0; */
  z-index: 10;
}

.App .Up-arrow.show {
  bottom: 40px;
  /* opacity: 1; */
}

.App .Up-arrow:hover {
  transform: scale(1.3);
}

@media screen and (max-width: 991px) {
  .App .Up-arrow {
    right: 10px;
    /* bottom: 10px; */
    bottom: -55px;
    width: 45px;
  }

  .App .Up-arrow.show {
    bottom: 10px;
    /* opacity: 1; */
  }

  .App .Up-arrow:hover {
    transform: none;
  }
}

.Prices {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 200px 1rem 300px;
  color: #fff;
  background-image: url("./back.jpg");
  background-size: cover;
  margin-top: -250px;
  z-index: 0;
}

.Prices .Prices-block {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1200px;
}

.Prices .Prices-block .Prices-content {
  padding: 2rem 3rem;
  display: flex;
  /* flex-wrap: wrap; */
}

.Prices .Prices-block .Prices-content .Prices-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 3rem;
  margin: 20px;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  box-shadow: 0px 5px 25px 0px rgb(0 0 0 / 20%);
}

.Prices .Prices-block .Prices-content .Prices-card img {
  height: 64px;
  width: 64px;
  margin: 15px 0;
}

.Prices .Prices-block .Prices-header {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;
  height: 5rem;
}

.Prices .Prices-block .Prices-header h2 {
  margin: 0 0 0 1rem;
  font-weight: 300;
  font-size: 3rem;
}

.Prices .Prices-block h3 {
  font-weight: 300;
  font-size: 2rem;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .Prices {
    z-index: unset;
    padding: 0;
    margin-top: 0;
  }

  .Prices .Prices-block {
    padding: 1rem;
  }

  .Prices .Prices-block .Prices-header {
    padding: 0.5rem;
    height: 4rem;
  }

  .Prices .Prices-block .Prices-header h2 {
    font-size: 2rem;
  }

  .Prices .Prices-block .Prices-content {
    padding: 0;
    flex-direction: column;
  }

  .Prices .Prices-block .Prices-content .Prices-card {
    padding: 2rem;
    margin: 1rem;
  }

  .Prices .Prices-block h3 {
    font-size: 1.7rem;
  }
}

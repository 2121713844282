.App .Welcome {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: #b4d3d6;
  z-index: 0;
}

.App .Welcome .Welcome-back {
  position: absolute;
  background-image: url("./welcome.jpg");
  background-size: cover;
  top: 0;
  height: 80vh;
  width: 100%;
  z-index: 1;
}

.App .Welcome .Welcome-logo-mobile {
  display: none;
  border-radius: 5px;
}

.App .Welcome .Welcome-logo-desktop {
  width: 40vw;
  filter: drop-shadow(16px 16px 20px #22587e5e);
  z-index: 2;
  border-radius: 5px;
}

.App .Welcome .Welcome-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #22577e;
  padding-left: 70px;
  z-index: 2;
}

.App .Welcome .Welcome-content h1 {
  font-size: 4rem;
  margin-bottom: 20px;
}

.App .Welcome .Welcome-content h3 {
  font-size: 2.5rem;
  margin: 0;
  font-weight: 100;
  margin-bottom: 20px;
}

.App .Welcome .Welcome-content .Welcome-buttonsContainer {
  margin-top: 2rem;
}

.App .Welcome .Welcome-content button {
  border-radius: 5px;
  padding: 1rem 3rem;
  width: 450px;
  font-weight: 200;
  transition: all 0.1s ease-in-out;
  font-size: 22px;
  z-index: 10;
}

.App .Welcome .Welcome-content button:hover {
  transform: scale(1.1);
}

.App .Welcome .Welcome-content .Welcome-consButton {
  border: none;
  background-color: #22577e;
  color: #fff;
}

@media screen and (max-width: 991px) {
  .App .Welcome {
    flex-direction: column;
    padding: 0 1rem;
    height: 100vh;
  }

  .App .Welcome .Welcome-logo-desktop {
    display: none;
  }

  .App .Welcome .Welcome-logo-mobile {
    display: block;
    width: 100%;
  }

  .App .Welcome .Welcome-content {
    padding: 10px;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 50px;
    height: 100%;
    max-height: 600px;
  }

  .App .Welcome .Welcome-content h1 {
    font-size: 2.2rem;
    margin-bottom: 20px;
    filter: drop-shadow(0 0 15px white);
  }

  .App .Welcome .Welcome-content h3 {
    font-size: 1.7rem;
    margin-top: 20px;
    font-weight: 400;
    filter: drop-shadow(0 0 10px white);
  }

  .App .Welcome .Welcome-content .Welcome-buttonsContainer {
    width: 100%;
  }

  .App .Welcome .Welcome-content button {
    border-radius: 5px;
    padding: 1rem 1rem;
    width: 100%;
    font-weight: 200;
    font-size: 1.2rem;
  }

  .App .Welcome .Welcome-back {
    height: 100vh;
  }
}

.App .About {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 1rem;
}

.App .About .About-block {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  box-shadow: 0px 5px 25px 0px rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: white;
}

.App .About .About-block .About-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  background: linear-gradient(
    263deg,
    rgba(85, 132, 172, 1) 0%,
    rgb(123, 176, 223) 100%
  );

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
  height: 5rem;
}

.App .About .About-block .About-header h2 {
  margin: 0;
  font-weight: 300;
  font-size: 3rem;
}

.App .About .About-block .About-content {
  padding: 2rem 3rem;
}

.App .About .About-block .About-content h3 {
  margin-top: 3rem;
  color: #22577e;
}

/* logo */
.App .About .About-block .About-content img {
  float: right;
  width: 320px;
  margin: 20px 20px 5px 0;
  padding-left: 55px;
}

/* diploms */
.App .About .About-block .About-content .About-diploms img {
  margin: 22px 2rem 1rem 0;
  width: 120px;
  padding: 0;
  float: left;
  /* transition: all 0.5s ease-in-out; */
}
.App .About .About-block .About-content .About-diploms img.vert {
  width: 84px;
}

/* .App .About .About-block .About-content .About-diploms img:hover {
  transform: scale(4);
} */

@media screen and (max-width: 991px) {
  .App .About {
    padding: 1rem 0 0;
  }

  .App .About .About-block .About-header {
    padding: 0.5rem;
    border-radius: unset;
    height: 4rem;
  }

  .App .About .About-block .About-header h2 {
    font-size: 2rem;
  }

  .App .About .About-block .About-content {
    padding: 1rem;
  }

  /* logo */
  .App .About .About-block .About-content img {
    width: 100%;
    margin: 0;
    padding: 0;
    float: unset;
  }

  /* diploms */
  .App .About .About-block .About-content .About-diploms img {
    margin: 3px;
    width: calc(50% - 6px);
  }
  .App .About .About-block .About-content .About-diploms img.vert {
    margin: 3px;
    width: calc(50% - 6px);
  }
  .App .About .About-block .About-content .About-diploms img:hover {
    transform: unset;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 19px;

  /* no select */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html {
  scroll-behavior: smooth;
}

/* scroll bar */
body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-track {
  background-color: rgb(216, 225, 252);
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(45, 53, 77);
}

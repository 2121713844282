.Consultation {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 1rem;
  z-index: 1;
}

.Consultation .Consultation-block {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1200px;
  background-color: #fff;
  box-shadow: 0px 5px 25px 0px rgb(0 0 0 / 20%);
  border-radius: 5px;
}

.Consultation .Consultation-block .Consultation-content h3 {
  color: #22577e;
  font-size: 1.7rem;
}

.Consultation .Consultation-block .Consultation-content > img {
  width: 300px;
  float: right;
}

.Consultation .Consultation-block .Consultation-content p {
  display: flex;
  align-items: center;
  margin: 4rem 0;
}

.Consultation .Consultation-block .Consultation-content p img {
  height: 70px;
  margin-right: 30px;
}

.Consultation .Consultation-block .Consultation-header {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
  height: 5rem;

  background: rgb(85, 132, 172);
  background: linear-gradient(
    263deg,
    rgba(85, 132, 172, 1) 0%,
    rgb(123, 176, 223) 100%
  );
  text-align: center;
}

.Consultation .Consultation-block .Consultation-header h2 {
  margin: 0;

  font-weight: 300;
  font-size: 3rem;
}

.Consultation .Consultation-block .Consultation-content {
  padding: 2rem 3rem;
}

@media screen and (max-width: 991px) {
  .Consultation {
    padding: 0;
  }

  .Consultation .Consultation-block {
    margin: 1rem 0 0;
    border-radius: unset;
  }

  .Consultation .Consultation-block .Consultation-header {
    border-radius: unset;
    padding: 0.8rem;
    height: 4rem;
  }

  .Consultation .Consultation-block .Consultation-header h2 {
    font-size: 2rem;
  }

  .Consultation .Consultation-block .Consultation-content {
    padding: 1rem;
  }

  .Consultation .Consultation-block .Consultation-content h3 {
    font-size: 1.5rem;
    text-align: center;
  }
  .Consultation .Consultation-block .Consultation-content img {
    float: unset;
    width: 100%;
    display: inline;
  }
  .Consultation .Consultation-block .Consultation-content p {
    margin: 1rem 0;
    align-items: flex-start;
    display: block;
  }
  .Consultation .Consultation-block .Consultation-content p img {
    height: 40px;
    width: 40px;
    float: left;
    margin: 8px 10px 0 0;
  }
}

.App .Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 1rem 0;

  background-image: url("./back.jpg");
  background-size: cover;
  background-attachment: fixed;

  color: #fff;
}

.App .Footer .Footer-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  padding: 2rem 3rem;
}

.App .Footer .Footer-block .Footer-row {
  display: flex;
}

.App .Footer .Footer-block .Footer-row .logo {
  width: 300px;
  margin-right: 90px;
}

.App .Footer .Footer-block .Footer-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App .Footer .Footer-block .Footer-column h2 {
  font-size: 2.2rem;
  margin: 40px 0 0;
}

.App .Footer .Footer-block .Footer-column p {
  font-size: 2rem;
}

.App .Footer .Footer-block .Footer-logoRow {
  display: flex;
  margin-bottom: 40px;
}

.App .Footer .Footer-block .Footer-logoRow img {
  width: 64px;
  height: 64px;
  margin-right: 30px;
  transition: all 0.1s ease-in-out;
}

.App .Footer .Footer-block .Footer-logoRow img:hover {
  transform: scale(1.3);
}

.App .Footer .Footer-block .Footer-copyright {
  margin-top: 50px;
}

@media screen and (max-width: 991px) {
  .App .Footer {
    padding: 1rem;
  }

  .App .Footer .Footer-block {
    padding: 0;
  }

  .App .Footer .Footer-block .Footer-row {
    flex-direction: column;
    align-items: center;
  }

  .App .Footer .Footer-block .Footer-row .logo {
    width: 150px;
    margin-right: 0;
  }

  .App .Footer .Footer-block .Footer-column {
    align-items: center;
  }

  .App .Footer .Footer-block .Footer-column h2 {
    font-size: 2rem;
    margin: 15px 0 0;
    text-align: center;
  }

  .App .Footer .Footer-block .Footer-column p {
    font-size: 1.5rem;
    text-align: center;
  }

  .App .Footer .Footer-block .Footer-logoRow {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    justify-content: center;
  }

  .App .Footer .Footer-block .Footer-logoRow img {
    width: 55px;
    height: 55px;
    margin: 20px;
    transition: none;
  }

  .App .Footer .Footer-block .Footer-copyright {
    margin-top: 10px;
  }

  .App .Footer .Footer-block .Footer-logoRow img:hover {
    transform: unset;
  }
}

.App .Work {
  background-image: url("./back.jpg");
  background-size: cover;
  background-attachment: fixed;
  padding: 6rem 1rem;
}

.App .Work .Work-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: auto;
}

.App .Work .Work-wrapper h2 {
  font-size: 3rem;
  margin: 5px 0 5px;
  font-weight: 300;
  color: #fff;
}

.App .Work .Work-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.App .Work-Card {
  max-width: 100%;
  min-height: 400px;
  box-shadow: 0px 5px 25px 0px rgb(0 0 0 / 20%);
  margin: 3rem 1rem 3rem 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #fff;
  padding-bottom: 20px;
  align-items: flex-start;
}
.App .Work-Card button {
  border: none;
  background: linear-gradient(
    263deg,
    rgba(34, 87, 126, 1) 0%,
    rgb(50, 131, 189) 100%
  );
  padding: 0.5rem 2rem;
  margin-left: 24px;
  border-radius: 5px;
  color: white;
  font-size: 17px;
  font-weight: bold;
  align-self: safe;
}

.App .Work-Card .Work-Card-Text {
  font-style: italic;
}

.App .Work-Card .Work-Card-Header {
  padding: 1rem;
  background: rgb(34, 87, 126);
  background: linear-gradient(
    263deg,
    rgba(34, 87, 126, 1) 0%,
    rgb(50, 131, 189) 100%
  );
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: white;
  height: 5rem;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  align-self: stretch;
}

.App .Work-Card .Work-Card-Header h3 {
  margin-left: 1rem;
  font-weight: 600;
}

.App .Work-Card p {
  padding: 0 2rem 0;
}

@media screen and (max-width: 991px) {
  .App .Work {
    padding: 1rem;
  }

  .App .Work .Work-wrapper h2 {
    font-size: 2rem;
    margin: 1rem 0;
  }

  .App .Work-Card {
    margin: 1rem 0rem;
    min-height: unset;
  }

  .App .Work-Card .Work-Card-Header {
    padding: 0.5rem 1rem;
    height: auto;
  }

  .App .Work-Card .Work-Card-Header h3 {
    margin: 1rem;
  }

  .App .Work-Card p {
    padding: 0;
    margin: 0.5rem 0.5rem 0.5rem 1.5rem;
  }
}
